import React, { Component } from 'react';
import './App.css';


export default class Footer extends Component { 
    
    render() {
        return(

            <div class="footer-block">
            <footer > 
                Developed and designed with <span style={{fontFamily: "Segoe UI", padding: "0"}}>♥</span> by Darien Hong &copy; 2025 
            </footer>
            </div> 

        );
    }
}